/* eslint-disable max-len */
import { transparentize } from 'polished';

const palette = {
  white: '#FFFFFF',
  black: '#000000',
  darkGray: '#1F1F1F',
  gray: '#C4C4C4',
  jakarta: '#3C467E',
  darkBlue: '#272D5B',
  disco: '#A70050',
  darkRed: '#4E0528',
  transparent: 'transparent',
  red: '#C60226',
  rombianPink: '#F22588',
  rombianBlack: '#1D1028',
  rombianAqua: '#5BFAFD',
  sunsetOrange: '#FF8A00',
  sunsetLightOrange: '#FFC37D',
  sunsetPink: '#E64EFF',
  sunsetBlack: '#1F1F1F',
  sunsetLightPink: '#E698FF',
  sunsetYellow: '#FFF500',
};

const colors = {
  // common
  text: palette.white,
  link: palette.sunsetPink,
  error: palette.red,
  background: palette.sunsetBlack,
  verticalSeparator: `linear-gradient(0deg, ${palette.sunsetOrange}  0%, ${palette.sunsetPink} 100%)`,
  horizontalSeparator: `linear-gradient(90deg, ${palette.sunsetOrange}  0%, ${palette.sunsetPink} 100%)`,
  // checkmark
  checkmarkPath: palette.sunsetPink,
  // button
  buttonBaseBackground: palette.transparent,
  buttonDisabledBackground: palette.transparent,
  buttonDisabledBorder: palette.gray,
  buttonDefaultBorder: palette.sunsetPink,
  buttonDefaultBackground: palette.transparent,
  buttonDefaultText: palette.sunsetPink,
  buttonPrimaryBorder: palette.sunsetOrange,
  buttonPrimaryBackground: palette.sunsetOrange,
  buttonPrimaryText: palette.sunsetBlack,
  buttonSecondaryBackground: palette.sunsetPink,
  buttonSecondaryBorder: palette.sunsetPink,
  buttonSecondaryText: palette.sunsetBlack,
  buttonDarkBackground: palette.white,
  buttonDarkBorder: palette.white,
  buttonDarkText: palette.sunsetBlack,
  buttonSubmitTalkBackground: `linear-gradient(180deg, ${palette.sunsetOrange}  0%, ${palette.sunsetPink} 100%)`,
  buttonSubmitTalkText: palette.white,
  buttonSubmitTalkBorder: palette.sunsetOrange,
  buttonSponsorUsBackground: `linear-gradient(180deg, ${palette.sunsetYellow}  0%, ${palette.sunsetOrange} 100%)`,
  buttonSponsorUsText: palette.black,
  buttonSponsorUsBorder: palette.sunsetYellow,
  // checkbox
  checkboxText: transparentize(0.3, palette.white),
  checkboxBorder: palette.gray,
  checkboxBackground: palette.transparent,
  checkboxCheckedBackground: palette.transparent,
  checkboxDisabledBackground: palette.transparent,
  checkboxDisabledCheckedBackground: palette.transparent,
  checkboxDisabledTextBackground: transparentize(0.5, palette.white),
  // input
  inputBackground: palette.transparent,
  inputBorder: palette.gray,
  inputBorderFocused: `linear-gradient(90deg, ${palette.sunsetOrange}  0%, ${palette.sunsetPink} 100%)`,
  inputPlaceholder: transparentize(0.65, palette.white),
  // textarea
  textareaBackground: palette.transparent,
  textareaBorder: palette.gray,
  textareaPlaceholder: transparentize(0.65, palette.white),
  // loading
  loadingBackground: palette.sunsetOrange,
  // Landing
  landingText: palette.white,
  landingBackgroundGradientStart: palette.darkBlue,
  landingBackgroundGradientEnd: palette.darkRed,
  landingHeaderTitleAccent: palette.rombianPink,
  landingHeaderGlass: palette.rombianBlack,
  landingHeaderBorder: palette.rombianBlack,
  landingHeaderLearnMoreArrow: palette.white,
  landingHeaderDate: palette.sunsetLightPink,
  landingHeaderVerticalSeparator: `linear-gradient(180deg, ${palette.sunsetLightOrange}  0%, #F094FF 100%)`,
  landingHeaderPlace: palette.white,
  landingHeaderVirtualPlace: palette.sunsetOrange,
  landingSectionTitleUnderline: palette.white,
  landingSectionTitleUnderlineAccent: palette.rombianPink,
  landingSpeakerPhotoBorder: palette.white,
  landingSpeakerPhotoBackground: palette.white,
  landingSpeakerFirstNameColor: palette.black,
  landingSpeakerFirstNameBackground: palette.white,
  landingSpeakerLastNameColor: palette.white,
  landingSpeakerModalCloseBackground: palette.black,
  landingSpeakerBioColor: palette.black,
  landingSpeakerDateTimeColor: palette.black,
  landingSpeakerDateTimeBackground: palette.white,
  landingSpeakerScheduleSaveColor: palette.white,
  landingSpeakerScheduleSaveBackground: palette.black,
  landingSpeakerVariantAMainColor: '#D4429A',
  landingSpeakerVariantBMainColor: '#42D5C3',
  landingSpeakerVariantCMainColor: '#4E42D4',
  landingSpeakerVariantDMainColor: '#FEB700',
  landingSpeakerVariantABioBackground: palette.white,
  landingSpeakerVariantBBioBackground: palette.white,
  landingSpeakerVariantCBioBackground: palette.black,
  landingSpeakerVariantDBioBackground: palette.white,
  landingSpeakerVariantABioColor: palette.black,
  landingSpeakerVariantBBioColor: palette.black,
  landingSpeakerVariantCBioColor: palette.white,
  landingSpeakerVariantDBioColor: palette.black,
  landingSpeakerVariantATalkColor: palette.black,
  landingSpeakerVariantBTalkColor: palette.black,
  landingSpeakerVariantCTalkColor: palette.white,
  landingSpeakerVariantDTalkColor: palette.black,
  landingSpeakerVariantATalkNameBackground: palette.black,
  landingSpeakerVariantBTalkNameBackground: palette.black,
  landingSpeakerVariantCTalkNameBackground: palette.white,
  landingSpeakerVariantDTalkNameBackground: palette.black,
  landingScheduleDayColor: palette.black,
  landingScheduleDayColorActive: palette.white,
  landingScheduleDayBackground: palette.white,
  landingScheduleDayBackgroundActive: palette.rombianPink,
  landingScheduleTalksBackground: palette.rombianBlack,
  landingFooterBackground: transparentize(0.55, palette.rombianBlack),
  // CFP
  cfpProgressTitle: palette.sunsetOrange,
  cfpProgressIncompleteStepBorder: transparentize(0.5, palette.white),
  cfpProgressFillBackground: `linear-gradient(90deg, ${palette.sunsetOrange} 0%, ${palette.sunsetPink} 100%);`,
  cfpProgressArrow: palette.sunsetPink,
  cfpStepTitle: palette.sunsetOrange,
  cfpStepFieldTitleSmall: transparentize(0.3, palette.white),
  cfpStepFieldDescription: transparentize(0.3, palette.white),
  cfpStepFieldHelp: transparentize(0.65, palette.white),
  cfpGuidelineCheckboxText: palette.white,
  cfpSuccessNote: transparentize(0.3, palette.white),
  cfpSuccessMobileBackground: palette.sunsetBlack,
  cfpSuccessMobileTitle: palette.sunsetOrange,
  cfpSuccessMobileNote: transparentize(0.5, palette.white),
};

const fonts = {
  default: 'Epilogue,Roboto,sans-serif',
};

const shadows = {};

const breakpoints = {
  desktop: '1024px',
  hiDpi: '2048px',
};

export const theme = {
  colors,
  fonts,
  shadows,
  breakpoints,
};
